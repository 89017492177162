<template>
  <div class="leaflet-container">
    <Breadcrumb class="d-none d-sm-flex px-8" :items="vBreadcrums" />
    <v-container v-if="leaflet && leafletRendered">
      <h1 class="mb-0 pb-0">{{ leaflet.title }}</h1>
      <h5 v-if="!LeafletRivista" class="mt-0 leaflet-validity-date">
        {{ $t("leaflet.validity.from") }}
        {{ leaflet.fromDate | dayjs("format", "D  MMMM") }}
        {{ $t("leaflet.validity.to") }}
        {{ leaflet.toDate | dayjs("format", "D  MMMM") }}
      </h5>
      <!-- <p v-if="leaflet.descr">{{ leaflet.descr }}</p> -->
    </v-container>
    <v-container class="upper">
      <!--applies class iosFullscreen on ios app and ios safari/chrome-->
      <div
        class="rounded-sm"
        :class="{ iosFullscreen: isIOSFullscreen }"
        id="leafet-viewer"
        ref="leafletViewer"
        style="background-color: var(--v-grey-lighten3) !important; border-color: var(--v-grey-lighten3) !important;"
      >
        <v-bottom-navigation
          id="leaflet-toolbar"
          dense
          flat
          background-color="grey lighten-3"
          color="primary"
          class="elevation-0"
        >
          <v-slide-group multiple show-arrows>
            <v-menu
              offset-y
              :close-on-content-click="false"
              :value="showSearch"
              attach="#leaflet-toolbar"
              content-class="elevation-0 rounded-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  @click="
                    showCategories = !showCategories;
                    onShowSearch();
                  "
                >
                  <span class="leaflet-container__tab--item default--text">{{
                    $t("leaflet.navigation.search")
                  }}</span>
                  <v-icon color="default" class="mb-2">$search</v-icon>
                </v-btn>
              </template>
              <div class="px-4 py-2 grey lighten-3">
                <v-text-field
                  v-model="search"
                  filled
                  solo
                  dense
                  flat
                  hide-details
                  single-line
                  height="48"
                  clearable
                  ref="flyerinput"
                  class="search-input default--text flyer-input"
                  @keydown.enter.native.prevent.stop="searchProducts"
                  ><template slot="append">
                    <v-icon
                      color="default"
                      class="mb-2"
                      @click.prevent.stop="searchProducts"
                      >$search</v-icon
                    >
                  </template></v-text-field
                >
              </div>
            </v-menu>
            <v-btn icon depressed @click="backToLeaflet"
              ><span class="leaflet-container__tab--item default--text">{{
                $t("leaflet.navigation.discover")
              }}</span>
              <v-icon color="default" class="mb-2">icon-sfoglia</v-icon>
            </v-btn>

            <v-btn icon color="default" depressed @click.stop="toggleFullscreen"
              ><span class="leaflet-container__tab--item default--text">{{
                $t("leaflet.navigation.zoom")
              }}</span>
              <v-icon color="default" class="mb-2">icon-zoom</v-icon>
            </v-btn>

            <v-menu
              offset-y
              attach="#leaflet-toolbar"
              content-class="rounded-0"
            >
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  depressed
                  @click="handleShowCategory"
                >
                  <span>{{ $t("leaflet.navigation.departments.title") }}</span>
                  <v-icon>icon-reparti</v-icon>
                </v-btn>
              </template> -->
              <v-sheet class="pa-2">
                <v-row no-gutters>
                  <v-col
                    v-for="category in categories"
                    :key="category.categoryId"
                    class="pa-1"
                    cols="3"
                    sm="2"
                    md="auto"
                  >
                    <v-btn
                      depressed
                      color="grey lighten-3"
                      width="73"
                      height="73"
                      class="category-leaflet-btn rounded-sm"
                      @click="
                        filterProducts(category.categoryId, category.name)
                      "
                    >
                      <div
                        class="d-flex flex-column justify-space-between h-100 py-2"
                      >
                        <div>
                          <ebsn-img
                            width="35"
                            height="35"
                            :target="category"
                            path="category_info.ICON"
                            :alt="`Icona Categoria ${category.name}`"
                          />
                        </div>
                        <div class="category-block-title primary--text">
                          <strong>{{ category.name }}</strong>
                        </div>
                      </div>
                    </v-btn>
                  </v-col>
                  <!-- <v-col
                    v-if="meritaAssaggio"
                    class="pa-1"
                    cols="3"
                    sm="2"
                    md="auto"
                  >
                    <v-btn
                      depressed
                      color="grey lighten-3"
                      width="73"
                      height="73"
                      class="category-leaflet-btn rounded-sm"
                      @click="filterProductsByCampaign(120, 'Merita Assaggio')"
                    >
                      <div
                        class="d-flex flex-column justify-space-between h-100 py-2"
                      >
                        <div>
                          <img
                            width="35"
                            height="35"
                            src="/img_layout/leaflet/merita_assaggio.png"
                            path="category_info.ICON"
                            alt="Icona campagna Merita Assaggio"
                          />
                        </div>
                      </div>
                    </v-btn>
                  </v-col> -->
                  <!-- <v-col
                    v-if="prodottiMese"
                    :key="category.categoryId"
                    class="pa-1"
                    cols="3"
                    sm="2"
                    md="auto"
                  >
                    <v-btn
                      depressed
                      color="grey lighten-3"
                      width="73"
                      height="73"
                      class="category-leaflet-btn rounded-sm"
                      @click="
                        filterProductsByCampaign(256, 'I prodotti del mese')
                      "
                    >
                      <div
                        class="d-flex flex-column justify-space-between h-100 py-2"
                      >
                        <div>
                          <img
                            width="35"
                            height="35"
                            src="/img_layout/leaflet/i_prodotti_del_mese.png"
                            path="category_info.ICON"
                            alt="Icona campagna i Prodotti del Mese"
                          />
                        </div>
                        <div class="category-block-title primary--text">
                          <strong>I Prodotti del mese</strong>
                        </div>
                      </div>
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-sheet>
            </v-menu>
            <v-btn
              v-if="leaflet"
              icon
              target="blank"
              :href="`${leaflet.baseLocation}${leaflet.location}`"
              @click="onLinkClick"
            >
              <span class="leaflet-container__tab--item default--text">{{
                $t("leaflet.navigation.download")
              }}</span>
              <v-icon color="default" class="mb-2">$download</v-icon>
            </v-btn>

            <v-btn
              @click.prevent.stop="openLeafletWarehouseList"
              v-if="leaflet && leaflet.leafletTypeId != 2"
              icon
            >
              <span class="leaflet-container__tab--item default--text">{{
                $t("leaflet.navigation.location")
              }}</span>
              <v-icon color="default" class="mb-2">$location</v-icon>
            </v-btn>
            <v-btn
              icon
              :to="{ name: 'LeafletList' }"
              v-if="leaflet && leaflet.leafletTypeId != 2"
            >
              <span class="leaflet-container__tab--item default--text">{{
                $t("leaflet.navigation.list")
              }}</span>
              <v-icon color="default" class="mb-2">icon-list</v-icon>
            </v-btn>
          </v-slide-group>
        </v-bottom-navigation>
        <router-view v-if="showList" />

        <leaflet-viewer
          v-else
          v-show="showLeaflet"
          :leaflet="leaflet"
          ref="leafletViewerRef"
          @openDetail="openDetail"
          @slideChanged="slideChanged"
          :key="leafletId"
          :swiperHeight="swiperHeight"
          :fullscreen="leafletFullscreen"
        />
      </div>
    </v-container>
    <v-container>
      <v-divider class="mb-5"></v-divider>
      <div v-if="LeafletRivista">
        <h3 class="default--text">
          {{ $t("leaflet.magazineText") }}
        </h3>
        <p>{{ $t("leaflet.rivista.subtitle") }}</p>
      </div>
      <h3 v-else class="default--text">{{ $t("leaflet.promoText") }}</h3>
    </v-container>

    <v-container v-if="!showList && !LeafletRivista">
      <h2 v-if="headerSearch">
        <span>{{ $t("leaflet.searchResults") }}: </span>
        <span class="font-weight-regular">{{ headerSearch }}</span>
      </h2>
      <h2 v-if="headerCategory">
        <span>{{ $t("leaflet.allOffersIn") }} </span>
        <span class="text-lowercase">{{ headerCategory }}</span>
      </h2>
      <v-row>
        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="3"
          xl="2"
          v-for="product in products"
          :key="product.productId"
        >
          <product-card-leaflet
            :product="product"
            :leaflet="leaflet"
            @openDetail="openDetailProduct"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="LeafletRivista">
      <LeafletMagazine />
    </v-container>

    <v-container>
      <router-link
        class="d-flex mt-6 align-center justify-sm-start mr-md-auto order-detail__actions--cta--back text-decoration-none"
        :to="{ path: '/negozi-e-volantini' }"
      >
        <v-icon class="mr-3 font-weight-bold" color="primary" size="30"
          >$arrowBack</v-icon
        >
        <span color="default" class="default--text back-btn font-weight-bold">{{
          $t("leaflet.back")
        }}</span>
      </router-link>
    </v-container>
  </div>
</template>
<style lang="scss">
/*
what it does? It disables scroll on ios safari/chrome when
flyer is in fullscreen
*/
.body-fixed-position {
  position: fixed;
}
/*
sets the flyer in fullscreen mode in ios safari/chrome
*/
.iosFullscreen {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 100vw;
  height: 100%;
  .disable-zoom-on-safari {
    touch-action: unset !important;
  }
  .swiper-pagination,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .draggable {
    pointer-events: none;
  }
  .swiper-container {
    max-width: unset !important;
    .imgage-container,
    .swiper-zoom-container {
      max-height: unset !important;
    }
  }
}

.leaflet-validity-date {
  font-weight: 400 !important;
}

article {
  counter-reset: my-counter;
  h1 {
    line-height: normal !important;
  }
  .leaflet-information-wrapper {
    //padding: 20px 0;

    .leaflet-information {
      flex: 1 1 33.333%;
      &.has-icon {
        max-width: fit-content;
      }
      .v-icon {
        font-size: 50px !important;
        background-color: var(--v-primary-base) !important;
        border-radius: 20px;
      }
      &-text {
        max-width: 120px;
      }
      .counter {
        position: relative;
        isolation: isolate;
        &::before {
          counter-increment: my-counter;
          content: counter(my-counter);
          position: absolute;
          z-index: -1;
          font-size: 100px;
          font-weight: bold;
          line-height: 65px;
          color: #fceba4;
        }
        &-left {
          &::before {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &-right {
          &::before {
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
@media (min-width: 960px) {
  article .leaflet-information-wrapper .leaflet-information .counter {
    &-left {
      &::before {
        transform: unset;
        left: -20px;
        top: 0;
      }
    }
    &-right {
      &::before {
        transform: unset;
        right: -20px;
        top: 0;
      }
    }
  }
}
#leaflet-toolbar {
  .v-btn__content {
    color: var(--v-primary-base);
    span {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
  &.v-item-group.v-bottom-navigation .v-btn {
    min-width: fit-content;
    padding: 10px;
  }
  .v-menu__content {
    max-width: 100%;
    left: 0 !important;
    right: 0;
    width: 100%;
  }
}
.leaflet-container {
  &__tab {
    &--item {
      font-weight: 400;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide-active {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .swiper-slide-next {
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .swiper-slide img {
    display: block;
    max-width: 100%;
    // height: 100%;
    object-fit: contain;
    max-height: inherit;
    // height: 100%;
  }

  .swiper-slide img {
    display: block;
    max-width: 100%;
    // height: 100%;
    object-fit: contain;
    max-height: inherit;
  }

  .imgage-container {
    box-sizing: content-box;
  }

  @media only screen and (min-width: 769px) {
    .swiper-slide:first-child {
      transition: transform 100ms;
    }

    .swiper-slide:first-child img {
      transition: box-shadow 500ms;
    }

    .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(50%);
      z-index: 2;
    }

    .swiper-slide.swiper-slide-active:first-child img {
      box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.35);
    }

    .swiper-slide:nth-child(2) {
      transition: transform 100ms;
    }

    .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(55%);
      z-index: 1;
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(-50%);
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(-55%);
    }

    .swiper-slide-active {
      -webkit-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
    }
  }

  .category-leaflet-btn {
    .v-btn__content {
      flex-direction: column;
      .category-block-title {
        font-size: 10px;
        font-weight: normal;
        text-transform: none;
        white-space: initial;
        max-width: 70px;
      }
    }
  }
  .leaflet-page-container {
    position: relative;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
  }

  // .draggable {
  //   cursor: pointer;
  //   position: absolute;
  //   &:hover {
  //     background-color: rgba(255, 221, 2, 0.35);
  //     border: 1px solid rgba(255, 221, 2, 1);
  //     transition: all 0.2s;
  //   }
  // }
  .image {
    max-width: 100%;
    max-height: 100%;
    // height: 100%;
  }
}
.fullscreen {
  .leaflet-container {
    .swiper-slide img {
      height: 100% !important;
    }
    .image {
      height: 100% !important;
    }
  }
}
.leaflet-dialog-fullscreen {
  display: flex;
  align-items: center;
  .exit-fullscreen-btn {
    position: absolute;
    z-index: 2;
    right: 10px;
  }
}
.v-dialog.vuedl-layout .v-btn {
  border: 0;
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import LeafletViewer from "@/components/leaflet/LeafletViewer.vue";
import LeafletMagazine from "@/components/leaflet/LeafletMagazine.vue";
import ProductCardLeaflet from "@/components/leaflet/ProductCardLeaflet.vue";
import ProductDetailLeaflet from "@/components/leaflet/ProductDetailLeaflet.vue";
import CTADetailLeaflet from "@/components/leaflet/CTADetailLeaflet.vue";
import LeafletInformationModal from "@/components/leaflet/LeafletInformationModal.vue";
import LeafletWarehouseList from "@/components/leaflet/LeafletWarehouseList.vue";
import customService from "@/service/customService";
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";
import leafletService from "~/service/leafletService";
import get from "lodash/get";
import { mapState } from "vuex";
//import isNumber from "lodash/isNumber";

const header = { fullscreenHeader: 165, mobileHeader: 300, desktopHeader: 40 };

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "Leaflet",
  components: {
    Breadcrumb,
    ProductCardLeaflet,
    LeafletMagazine,
    // LeafletWarehouseList,
    LeafletViewer
  },
  data() {
    return {
      isIOSFullscreen: false,
      leafletId: null,
      areaDetail: null,
      categories: [],
      showCategories: false,
      leaflet: {},
      leafletRendered: false,
      selectedArea: null,
      products: null,
      openProductDetail: false,
      showLeaflet: true,
      showSearch: false,
      search: "",
      leafletFullscreen: false,
      headerSearch: null,
      headerCategory: null,
      currentPage: 0,
      showList: false,
      dialog: false,
      swiperHeight: this.$vuetify.breakpoint.mdAndUp
        ? window.innerHeight - header.desktopHeader + "px"
        : window.innerHeight - header.mobileHeader + "px"
    };
  },
  mixins: [clickHandler, downloadDocument],
  computed: {
    ...mapState({
      allCategories: ({ category }) => category.categoryTree
    }),
    LeafletRivista() {
      return this.leaflet.leafletTypeId == 2;
    },
    // meritaAssaggio() {
    //   let foundCampaign = this.facets
    //     ?.find(facet => facet.name == "Campaign")
    //     ?.find(facet => facet.name == "Merita Assaggio");
    //   return foundCampaign != null;
    // },
    // prodottiMese() {
    //   let foundCampaign = this.facets
    //     ?.find(facet => facet.name == "Campaign")
    //     ?.find(facet => facet.name == "Prodotti del mese");
    //   return foundCampaign != null;
    // },
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      // b.push({
      //   to: {
      //     name: "Leaflets"
      //   },
      //   text: "Volantini",
      //   exact: true
      // });
      if (this.leaflet) {
        b.push({
          to: {
            name: "Leaflet",
            params: {
              volantino: this.$route.params.volantino
            }
          },
          text: this.leaflet.title,
          exact: true
        });
      }
      if (this.showList) {
        b.push({
          to: {
            name: "LeafletList"
          },
          text: "Lista",
          exact: true
        });
      }
      return b;
    },
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "50%";
        case "lg":
          return "40%";
        case "xl":
          return "30%";
        default:
          return "auto";
      }
    },
    /**
     * Return true if the user is using ios safari/chrome
     */
    isIOSSafariOrChrome() {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iP(ad|hone)/i);
      const webkit = !!ua.match(/WebKit/i);
      const isChrome = !!ua.match(/Chrome/i);

      return (iOS && webkit) || (iOS && isChrome);
    }
  },
  methods: {
    setLeaflet(leaflet) {
      this.leaflet = leaflet;
    },

    /**
     * Handles pinchzoom behaviour on ios app in fullscreen mode
     */
    handleZoom() {
      let swiperInstance = this.$refs.leafletViewerRef.$refs.swiper.$swiper;
      if (this.isIOSFullscreen) {
        swiperInstance.zoom.enable();
        console.log(swiperInstance);
      } else {
        swiperInstance.zoom.disable();
      }
    },

    handleShowCategory() {
      this.showCategories = !this.showCategories;
      this.isIOSFullscreen = false;
      this.handleZoom();
    },

    async toggleFullscreen() {
      const _this = this;
      /**
       * Return true if the user is using ios app or ios safari/chrome
       */
      if (
        this.isIOSSafariOrChrome ||
        (window.cordova && window.cordova.platformId.toLowerCase() === "ios")
      ) {
        /**
         * Show flyer in fullscreen mode
         */

        this.isIOSFullscreen = true;
        this.handleZoom();
        this.showLeaflet = true;
      } else {
        if (_this.leafletFullscreen) {
          document.exitFullscreen().then(function() {
            if (_this.$vuetify.breakpoint.mdAndUp) {
              _this.swiperHeight =
                window.innerHeight - header.desktopHeader + "px";
              console.log(_this.swiperHeight);
            } else {
              _this.swiperHeight =
                window.innerHeight - header.mobileHeader + "px";
              console.log(_this.swiperHeight);
            }
          });
        } else if (document.webkitIsFullScreen == true) {
          document.webkitExitFullscreen().then(function() {
            if (_this.$vuetify.breakpoint.mdAndUp) {
              _this.swiperHeight =
                window.innerHeight - header.desktopHeader + "px";
              console.log(_this.swiperHeight);
            } else {
              _this.swiperHeight =
                window.innerHeight - header.mobileHeader + "px";
              console.log(_this.swiperHeight);
            }
          });
        } else {
          this.showLeaflet = true;
          let elem = this.$refs.leafletViewer;
          var methodToBeInvoked =
            elem.requestFullscreen ||
            elem.webkitRequestFullScreen ||
            elem["mozRequestFullscreen"] ||
            elem["msRequestFullscreen"];
          let _this = this;
          if (methodToBeInvoked) {
            methodToBeInvoked.call(elem).then(
              function() {
                _this.swiperHeight =
                  window.screen.height - header.fullscreenHeader + "px";
                console.log(_this.swiperHeight);
              },
              function(error) {
                console.log(error);
              }
            );
          }
        }
      }
    },

    exitFullscreen() {
      if (this.leafletFullscreen) {
        this.toggleFullscreen();
      }
    },
    exitHandler() {
      let attr =
        document.fullscreen === true ||
        document.webkitIsFullScreen === true ||
        document.mozFullScreen === true ||
        document.msFullscreenElement === true;

      if (attr === false) {
        this.leafletFullscreen = false;
      } else if (attr === true) {
        this.leafletFullscreen = true;
      }
    },
    async openDetail(areaDetail) {
      //let slug = "asiago-fresco-dop-fette-gr100-agriform";
      this.exitFullscreen();
      this.selectedArea = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_leaflet_area_id: areaDetail.leafletAreaId,
        details: true
      });
      if (
        areaDetail &&
        areaDetail.leafletAreaTypeId &&
        areaDetail.leafletAreaTypeId === 5
      ) {
        if (
          areaDetail.metadata &&
          areaDetail.metadata.leafletareatype_cta &&
          areaDetail.metadata.leafletareatype_cta.IMAGE &&
          areaDetail.metadata.leafletareatype_cta.IMAGE.location
        ) {
          this.$dialog.show(CTADetailLeaflet, {
            icon: areaDetail ? areaDetail.iconType : undefined,
            areaDetail: areaDetail,
            products: this.selectedArea.data.products,
            leaflet: this.leaflet,
            width: this.dialogWidth,
            fullscreen: this.$vuetify.breakpoint.xsOnly
          });
        }
        return;
      } else {
        if (this.selectedArea && this.selectedArea.data.products.length > 0) {
          this.$dialog.show(ProductDetailLeaflet, {
            icon: areaDetail ? areaDetail.iconType : undefined,
            products: this.selectedArea.data.products,
            leaflet: this.leaflet,
            width: this.dialogWidth,
            fullscreen: this.$vuetify.breakpoint.xsOnly
          });
        }
      }
    },
    async openDetailProduct(productId) {
      //let slug = "asiago-fresco-dop-fette-gr100-agriform";
      this.exitFullscreen();
      this.selectedArea = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_product_id: productId,
        details: true
      });
      if (this.selectedArea && this.selectedArea.data.products.length > 0) {
        // this.openProductDetail = true;
        this.$dialog.show(ProductDetailLeaflet, {
          products: this.selectedArea.data.products,
          leaflet: this.leaflet,
          width: this.dialogWidth,
          fullscreen: this.$vuetify.breakpoint.xsOnly
        });
      }
    },
    async slideChanged(index) {
      console.log("LEAFLET:", index);
      this.currentPage = index;

      if (window.innerWidth > 769 && index != 0) {
        index = [index, index + 1];
      }
      this.fetchProducts(index);
    },
    async fetchLeaflet() {
      if (isNaN(this.leafletId)) {
        this.leaflet = await customService.getLeafletBySlug(this.leafletId);
      } else {
        this.leaflet = await leafletService.getLeafletById(this.leafletId);
      }

      await this.fetchFacets();
      // Display leaflet Title and date after the container is fully loaded.
      this.leafletRendered = true;

      let foundCategories = this.facets?.find(
        facet => facet.name == "Categorie"
      );

      this.categories = this.allCategories.filter(aCategory => {
        let available = foundCategories?.values?.find(
          fCategory => fCategory.id == aCategory.categoryId
        );
        return available != null;
      });
    },
    async fetchProducts(pageNumber) {
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_leaflet_page_number_id: pageNumber
      });
      if (response) {
        this.products = response.data.products;
      }
    },

    async fetchFacets() {
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        page_size: 0
      });
      this.facets = response.data.facets;
    },

    async filterProducts(categoryId, categoryName) {
      this.exitFullscreen();
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_category_id: categoryId
      });
      this.headerSearch = null;
      this.headerCategory = categoryName;
      this.products = response.data.products;
      this.showLeaflet = false;
      if (this.showList) {
        this.$router.push({ name: "Leaflet" });
      }
    },

    async searchProducts() {
      this.exitFullscreen();
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        q: this.search
      });
      this.headerCategory = null;
      this.headerSearch = this.search;
      this.products = response.data.products;
      var el = document.getElementsByClassName("flyer-input");
      el[0].blur();
      this.iosFullscreen = false;
      const iosFullscreen = document.getElementById("leafet-viewer");
      iosFullscreen.classList.remove("iosFullscreen");
      this.showSearch = false;
      this.showLeaflet = false;
      if (this.showList) {
        this.$router.push({ name: "Leaflet" });
      }
      this.$refs.flyerinput.blur();
    },

    backToLeaflet() {
      this.exitFullscreen();

      if (this.isIOSFullscreen) {
        this.isIOSFullscreen = false;
      }
      this.handleZoom();

      this.showLeaflet = true;
      this.headerSearch = null;
      this.headerCategory = null;
      if (this.showList) {
        this.$router.push({ name: "Leaflet" });
      }
    },

    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else {
          this.clicked(e);
        }
      });
    },

    onShowSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        var el = document.getElementsByClassName("flyer-input");
        el[0].blur();
      }
    },
    openLeafletWarehouseList() {
      this.$dialog.show(LeafletWarehouseList, {
        width: this.dialogWidth,
        leafletTypeId: this.leaflet.leafletTypeId,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
    },
    openleafletInfoModal() {
      this.$dialog.show(LeafletInformationModal, {
        width: this.dialogWidth,
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
    }
  },
  metaInfo() {
    return {
      title: get(this.leaflet, "title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.leaflet, "description")
        },
        { name: "viewport", content: "width=device-width, user-scalable=no" }
      ]
    };
  },
  async mounted() {
    this.page = this.$route.params.page;
    console.log("BBBBBBB", isNaN(this.$route.params.leafletId));
    // if (isNumber(this.$route.params.leafletId)) {
    //   this.leafletId = this.$route.params.leafletId;
    // } else {
    //   this.leafletId = this.$route.params.leafletId;
    // }
    this.leafletId = this.$route.params.leafletId;
    await this.fetchLeaflet();
    await this.fetchProducts(0);

    if (this.$route.name === "LeafletList") {
      this.showList = true;
    } else {
      this.showList = false;
    }

    let elem = this.$refs.leafletViewer;
    elem.addEventListener("fullscreenchange", this.exitHandler, false);
    elem.addEventListener("mozfullscreenchange", this.exitHandler, false);
    elem.addEventListener("MSFullscreenChange", this.exitHandler, false);
    elem.addEventListener("webkitfullscreenchange", this.exitHandler, false);
    console.log(this.swiperHeight);

    global.EventBus.$on("openDetail", areaDetail => {
      this.openDetail(areaDetail);
    });

    global.EventBus.$on("slideChanged", index => {
      this.slideChanged(index);
    });
  },
  unmouted() {
    let elem = this.$refs.leafletViewer;
    elem.removeEventListener("fullscreenchange", this.exitHandler);
    elem.removeEventListener("mozfullscreenchange", this.exitHandler);
    elem.removeEventListener("MSFullscreenChange", this.exitHandler);
    elem.removeEventListener("webkitfullscreenchange", this.exitHandler);
  },
  watch: {
    "$route.params.leafletId": async function() {
      // if (isNaN(this.$route.params.leafletId)) {
      //   this.leafletId = this.$route.params.leafletId;
      // } else {
      //   this.leafletId = this.$route.params.leafletId;
      // }
      this.leafletId = this.$route.params.leafletId;
      this.fetchLeaflet();
      this.fetchProducts(0);
    },
    /**
     * Add class to disable background scrolling if leaflet is in fullscreen in ios safari/chrome
     */
    isIOSFullscreen(newValue) {
      if (newValue) {
        document.body.classList.add("body-fixed-position");
      } else {
        document.body.classList.remove("body-fixed-position");
      }
    }
  }
};
</script>
